import { ChainId, JSBI, Percent, Token } from '@pancakeswap/sdk'
import { BigNumber } from '@ethersproject/bignumber'
import { petaTokens, bscTokens } from '@pancakeswap/tokens'
import { ChainMap, ChainTokenList, RouterAddressTypes } from './types'

export const ROUTER_ADDRESS_COMMON = '0xeF682FfCD71eAda8435D048774Ff51D8C8601730'
export const ROUTER_ADDRESS_COMMON_AKKA_PETA = '0xeF682FfCD71eAda8435D048774Ff51D8C8601730'
export const ROUTER_ADDRESS_COMMON_BSC = '0x10ED43C718714eb63d5aA57B78B54704E256024E'
export const ROUTER_ADDRESS_COMMON_MATIC = '0x6ee7d83766922E72bE9Dd4Fe6f23ADBd688766B5'

export const ROUTER_ADDRESS: Partial<ChainMap<RouterAddressTypes>> = {
  [ChainId.PETA]: {
    Icecream: ROUTER_ADDRESS_COMMON,
    Akka: ROUTER_ADDRESS_COMMON_AKKA_PETA,
  },
  [ChainId.BSC]: {
    Icecream: ROUTER_ADDRESS_COMMON_BSC,
  },
  [ChainId.MATIC]: {
    Icecream: ROUTER_ADDRESS_COMMON_MATIC,
  },
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: Partial<ChainTokenList> = {
  [ChainId.PETA]: [petaTokens.wpeta, petaTokens.swich, petaTokens.usdt, petaTokens.wbnb],
  [ChainId.BSC]: [bscTokens.wbnb, bscTokens.cake, bscTokens.usdt, bscTokens.test],
}

/**
 * Additional bases for specific tokens
 * @example { [WBTC.address]: [renBTC], [renBTC.address]: [WBTC] }
 */
export const ADDITIONAL_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 * @example [AMPL.address]: [DAI, WNATIVE[ChainId.BSC]]
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: Partial<ChainTokenList> = {
  [ChainId.PETA]: [
    petaTokens.swich,
    petaTokens.usdt,
    petaTokens.arbitrum,
    petaTokens.babydoge,
    ,
    petaTokens.floki,
    petaTokens.link,
    ,
    petaTokens.wbnb,
    petaTokens.weth,
    petaTokens.wbtc,
  ],
  [ChainId.BSC]: [bscTokens.cake, bscTokens.wbnb, bscTokens.usdt],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: Partial<ChainTokenList> = {
  [ChainId.PETA]: [
    petaTokens.arbitrum,
    petaTokens.babydoge,
    petaTokens.floki,
    petaTokens.usdt,
    petaTokens.wbtc,
    petaTokens.weth,
    petaTokens.wpeta,
    petaTokens.link,
    petaTokens.onx,
    petaTokens.pepe,
    petaTokens.swich,
    petaTokens.trac,
    petaTokens.wbnb,
    petaTokens.wmatic,
    petaTokens.wpeta,
  ],
  [ChainId.BSC]: [bscTokens.bnb, bscTokens.cake, bscTokens.usdt],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.PETA]: [
    [petaTokens.wpeta, petaTokens.swich],
    [petaTokens.swich, petaTokens.usdt],
    [petaTokens.wpeta, petaTokens.wbnb],
    [petaTokens.arbitrum, petaTokens.floki],
  ],
  [ChainId.BSC]: [
    [bscTokens.wbnb, bscTokens.cake],
    [bscTokens.usdt, bscTokens.bnb],
    [bscTokens.wbnb, bscTokens.test],
  ],
}

export const BIG_INT_ZERO = JSBI.BigInt(0)
export const BIG_INT_TEN = JSBI.BigInt(10)

// one basis point
export const BIPS_BASE = JSBI.BigInt(10000)
export const ONE_BIPS = new Percent(JSBI.BigInt(1), BIPS_BASE)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much BNB so they end up with <.01
export const MIN_BNB: JSBI = JSBI.multiply(JSBI.exponentiate(BIG_INT_TEN, JSBI.BigInt(16)), JSBI.BigInt(6)) // .06 NativeToken
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), BIPS_BASE)

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

export const BASE_FEE = new Percent(JSBI.BigInt(30), BIPS_BASE)
export const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(BASE_FEE)

// BNB
export const DEFAULT_INPUT_CURRENCY = 'BNB'
// ICE
export const DEFAULT_OUTPUT_CURRENCY = '0xc78Ad7326bB4612A231ba265dd9b3600EA49f4Cf'

// Handler string is passed to Gelato to use PCS router
export const GELATO_HANDLER = 'pancakeswap'
export const GENERIC_GAS_LIMIT_ORDER_EXECUTION = BigNumber.from(500000)

export const LIMIT_ORDERS_DOCS_URL = 'https://docs.petaswap.io/products/pancakeswap-exchange/limit-orders'

export const EXCHANGE_PAGE_PATHS = ['/swap', '/limit-orders', 'liquidity', '/add', '/find', '/remove']
