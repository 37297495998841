import { ChainId } from '@pancakeswap/sdk'
import FarmsPetaPriceHelper from './234'

// todo: make dynamic
export const getFarmsPriceHelperLpFiles = (chainId: ChainId) => {
  switch (chainId) {
    case ChainId.PETA:
      return FarmsPetaPriceHelper
    default:
      return []
  }
}
