import { BigNumber } from '@ethersproject/bignumber'
import { PoolCategory, SerializedPoolConfig } from './types'
import { petaTokens } from '@pancakeswap/tokens'

export const MAX_LOCK_DURATION = 31536000
export const UNLOCK_FREE_DURATION = 604800
export const ONE_WEEK_DEFAULT = 604800
export const BOOST_WEIGHT = BigNumber.from('20000000000000')
export const DURATION_FACTOR = BigNumber.from('31536000')

export const vaultPoolConfig = {} as const

export const livePools: SerializedPoolConfig[] = [
  {
    sousId: 1,
    stakingToken: petaTokens.usdt,
    earningToken: petaTokens.usdt,
    contractAddress: {
      234: '0xa630eeEAD5606076DC977Db66ff8ced0Eb01Aa5F',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.000578',
    version: 2,
  },
  {
    sousId: 2,
    stakingToken: petaTokens.usdt,
    earningToken: petaTokens.wpeta,
    contractAddress: {
      234: '0xE81D4978B099a9Aa4a157444fF8AcA73C42e6Cd2',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.001157',
    version: 2,
  },
  {
    sousId: 3,
    stakingToken: petaTokens.trac,
    earningToken: petaTokens.trac,
    contractAddress: {
      234: '0xE88BcF8705f6D866643E92698D82a88861E5B1E6',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0057870',
    version: 2,
  },
  {
    sousId: 4,
    stakingToken: petaTokens.wpeta,
    earningToken: petaTokens.swich,
    contractAddress: {
      234: '0x4aC398329419512fFC7e0f0f490B67c83866B425',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0115740',
    version: 2,
  },
].map((p) => ({
  ...p,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

// known finished pools
const finishedPools = [].map((p) => ({
  ...p,
  isFinished: true,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

export default [...livePools, ...finishedPools]
