import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg
      style={{
        height: "24px",
        width: "100%",
        top: 2,
      }}
      viewBox="0 0 20 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_994_27192)">
        <path
          d="M20.227.75H5.562c-.838 0-1.643.337-2.236.936a3.213 3.213 0 00-.926 2.26v18.732c0 .36.141.704.393.958.251.254.593.397.948.397h3.413c.356 0 .697-.143.948-.397.252-.254.393-.599.393-.958v-.672h8.8v.672c0 .36.14.704.392.958s.593.397.948.397h3.37c.355 0 .696-.143.948-.397.251-.254.393-.599.393-.958V3.945c0-.84-.327-1.646-.911-2.244A3.146 3.146 0 0020.227.75zm1.527 21.597H18.93v-.661a1.37 1.37 0 00-.354-.975 1.341 1.341 0 00-.933-.435H8.255a1.33 1.33 0 00-.948.397 1.355 1.355 0 00-.393.958v.672H4.09V3.901c0-.409.161-.801.447-1.09.287-.29.675-.452 1.08-.452h14.665c.405 0 .793.162 1.08.452.285.289.446.681.446 1.09l-.054 18.446zM17.86 6.634l-.338-.341a.348.348 0 00-.501 0l-1.636 1.652a4.115 4.115 0 00-4.983 0L8.78 6.26a.36.36 0 00-.513 0l-.338.342a.364.364 0 00-.08.4.364.364 0 00.08.118l1.625 1.642a4.25 4.25 0 000 5.035L7.928 15.45a.353.353 0 000 .507l.338.342a.36.36 0 00.513 0l1.624-1.642a4.146 4.146 0 004.983 0l1.636 1.642a.35.35 0 00.387.08.349.349 0 00.114-.08l.338-.342a.353.353 0 00.079-.39.353.353 0 00-.079-.117l-1.624-1.653a4.25 4.25 0 00.824-2.517c0-.907-.289-1.79-.824-2.518l1.624-1.642a.366.366 0 000-.485zm-4.96 5.884a1.18 1.18 0 01-.664-.197 1.213 1.213 0 01-.192-1.85 1.184 1.184 0 011.296-.264c.218.09.405.244.536.442a1.21 1.21 0 01-.144 1.514c-.22.224-.52.352-.833.355z"
          fill="#596780"
        />
      </g>
      <defs>
        <clipPath id="clip0_994_27192">
          <path fill="#fff" transform="translate(.9)" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Icon;
