import { ChainId, WETH9, ERC20Token } from '@pancakeswap/sdk'

export const petaTokens = {
  wpeta: WETH9[ChainId.PETA],
  swich: new ERC20Token(
    ChainId.PETA,
    '0x0686848c185d275EDF88567E6382b494a3b94F74',
    18,
    'SWICH',
    'Sandwich',
    'https://petaswap.com',
  ),
  arbitrum: new ERC20Token(ChainId.PETA, '0xf3f14FD644772Fa882f135AF343382422b5Cc241', 18, 'ARB', 'Arbitrum'),
  babydoge: new ERC20Token(ChainId.PETA, '0x644f3F4b8751d17E5F511ab844A86E29eC295e28', 9, 'BABYDOGE', 'Baby Doge Coin'),
  demo: new ERC20Token(ChainId.PETA, '0xb51Eec726DD9986bA48c0c164589eC6d77A879D0', 18, 'DEMO', 'Demo Token'),
  floki: new ERC20Token(ChainId.PETA, '0x2da140f9eE7dE38EBbf4998520320D3acCfccaD1', 9, 'FLOKI', 'FLOKI'),
  link: new ERC20Token(ChainId.PETA, '0x878dd5259Aafbb79541aC961E0928f2e4fA99cFe', 18, 'LINK', 'LINK'),
  onx: new ERC20Token(ChainId.PETA, '0x1C079Cff536de408B722A9D2c99ecf91Fc7201EC', 18, 'ONX', 'OnexPlusToken'),
  trac: new ERC20Token(ChainId.PETA, '0x545b445DB4A3808846f1AAe6cdc3CC30c6569575', 18, 'TRAC', 'Ontrac'),
  pepe: new ERC20Token(ChainId.PETA, '0x7A9909fdBfC3A916D61A91c1F6361232038B9980', 18, 'PEPE', 'Pepe Token'),
  usdt: new ERC20Token(ChainId.PETA, '0xa18780142b5CaC697C560767695Cdd72Fa03A1A0', 18, 'USDT', 'Tether USD'),
  wbnb: new ERC20Token(ChainId.PETA, '0x71E5aBA515b8575F9a3849F26474521642932867', 18, 'WBNB', 'Wrapped BNB'),
  wbtc: new ERC20Token(ChainId.PETA, '0xA4B4C411A32D727Bd1F2a1fDF8Ae52289bFEFE76', 18, 'WBTC', 'Wrapped Bitcoin'),
  weth: new ERC20Token(ChainId.PETA, '0x6ee7d83766922E72bE9Dd4Fe6f23ADBd688766B5', 18, 'WETH', 'Wrapped Ethereum'),
  wmatic: new ERC20Token(ChainId.PETA, '0x091881f1119648CBd5fa575330d215bE1bB40A1A', 18, 'WMATIC', 'Wrapped Matic'),
}
