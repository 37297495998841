export const baseColors = {
  white: 'white',
  failure: '#ED4B9E',
  failure33: '#ED4B9E33',
  primary: 'var(--Background-Gradient, linear-gradient(180deg, #0163e0 0%, #08b2d2 100%))',
  primary0f: '#1FC7D40f',
  primary3D: '#1FC7D43D',
  primaryBright: '#53DEE9',
  primaryDark: '#0098A1',
  success: '#31D0AA',
  success19: '#31D0AA19',
  warning: '#FFB237',
  warning2D: '#ED4B9E2D',
  warning33: '#ED4B9E33',
}

export const additionalColors = {
  binance: '#F0B90B',
  overlay: '#452a7a',
  gold: '#FFC700',
  silver: '#B2B2B2',
  bronze: '#E7974D',
}

export const lightColors = {
  ...baseColors,
  ...additionalColors,
  secondary: '#0163E0',
  secondary2: '#08B2D2',
  secondary80: '#9A6AFF80',
  background: '#08060B',
  backgroundDisabled: '#3c3742',
  backgroundAlt: '#111527',
  backgroundAlt2: 'rgba(39, 38, 44, 0.7)',
  backgroundBody: '#13182D',
  backgroundBadge: '#191F39',
  cardBorder: '#383241',
  contrast: '#FFFFFF',
  dropdown: '#1E1D20',
  dropdownDeep: '#100C18',
  headerBackground: '#111527',
  invertedContrast: '#111527',
  input: '#191F39',
  inputSecondary: '#262130',
  primaryDark: '#0098A1',
  tertiary: '#1b1e29',
  text: '#F4EEFF',
  text99: '#F4EEFF99',
  textDisabled: '#666171',
  textSubtle: '#90A3BF',
  textSubtle2: '#596780',
  disabled: '#524B63',
  gradientPeta: 'linear-gradient(180deg, #0163E0 0%, #08B2D2 100%)',
  gradientBubblegum: 'linear-gradient(139.73deg, #12131a 0%, #12131a 100%)',
  gradientInverseBubblegum: 'linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%)',
  gradientCardHeader: 'linear-gradient(166.77deg, #232734 0%, #232734 100%)',
  gradientBlue: 'linear-gradient(180deg, #00707F 0%, #19778C 100%)',
  gradientViolet: 'linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)',
  gradientVioletAlt: 'linear-gradient(180deg, #434575 0%, #66578D 100%)',
  gradientGold: 'linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)',
  gradientBold: 'linear-gradient(#53DEE9, #9A6AFF)',
}
// export const lightColors = {
//   ...baseColors,
//   ...additionalColors,
//   secondary: '#0163E0',
//   secondary2: '#08B2D2',
//   secondary80: '#7645D980',
//   background: '#FAF9FA',
//   backgroundDisabled: '#E9EAEB',
//   backgroundAlt: '#FFFFFF',
//   backgroundAlt2: 'rgba(255, 255, 255, 0.7)',
//   backgroundBody: '#191F39',
//   backgroundBadge: '#191F39',
//   cardBorder: '#E7E3EB',
//   contrast: '#191326',
//   dropdown: '#F6F6F6',
//   dropdownDeep: '#EEEEEE',
//   headerBackground: '#111527',
//   invertedContrast: '#FFFFFF',
//   input: '#191F39',
//   inputSecondary: '#d7caec',
//   tertiary: '#EFF4F5',
//   text: '#280D5F',
//   text99: '#280D5F99',
//   textDisabled: '#BDC2C4',
//   textSubtle: '#7A6EAA',
//   textSubtle2: '#596780',
//   disabled: '#E9EAEB',
//   gradientPeta: 'linear-gradient(180deg, #0163E0 0%, #08B2D2 100%)',
//   gradientBubblegum: 'linear-gradient(139.73deg, #E5FDFF 0%, #F3EFFF 100%)',
//   gradientInverseBubblegum: 'linear-gradient(139.73deg, #F3EFFF 0%, #E5FDFF 100%)',
//   gradientCardHeader: 'linear-gradient(111.68deg, #F2ECF2 0%, #E8F2F6 100%)',
//   gradientBlue: 'linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%)',
//   gradientViolet: 'linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%)',
//   gradientVioletAlt: 'linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%)',
//   gradientGold: 'linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)',
//   gradientBold: 'linear-gradient(#53DEE9, #7645D9)',
// }

export const darkColors = {
  ...baseColors,
  ...additionalColors,
  secondary: '#0163E0',
  secondary2: '#08B2D2',
  secondary80: '#9A6AFF80',
  background: '#08060B',
  backgroundDisabled: '#3c3742',
  backgroundAlt: '#111527',
  backgroundAlt2: 'rgba(39, 38, 44, 0.7)',
  backgroundBody: '#13182D',
  backgroundBadge: '#191F39',
  cardBorder: '#383241',
  contrast: '#FFFFFF',
  dropdown: '#1E1D20',
  dropdownDeep: '#100C18',
  headerBackground: '#111527',
  invertedContrast: '#111527',
  input: '#191F39',
  inputSecondary: '#262130',
  primaryDark: '#0098A1',
  tertiary: '#1b1e29',
  text: '#F4EEFF',
  text99: '#F4EEFF99',
  textDisabled: '#666171',
  textSubtle: '#90A3BF',
  textSubtle2: '#596780',
  disabled: '#524B63',
  gradientPeta: 'linear-gradient(180deg, #0163E0 0%, #08B2D2 100%)',
  gradientBubblegum: 'linear-gradient(139.73deg, #12131a 0%, #12131a 100%)',
  gradientInverseBubblegum: 'linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%)',
  gradientCardHeader: 'linear-gradient(166.77deg, #232734 0%, #232734 100%)',
  gradientBlue: 'linear-gradient(180deg, #00707F 0%, #19778C 100%)',
  gradientViolet: 'linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)',
  gradientVioletAlt: 'linear-gradient(180deg, #434575 0%, #66578D 100%)',
  gradientGold: 'linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)',
  gradientBold: 'linear-gradient(#53DEE9, #9A6AFF)',
}
