import { Flex, Button, Text, QuestionHelper } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { useGasPriceManager } from '../../../state/user/hooks'
import { GAS_PRICE_GWEI, GAS_PRICE } from '../../../state/types'

const GasSettings = () => {
  const { t } = useTranslation()
  const [gasPrice, setGasPrice] = useGasPriceManager()
  const activeBg = 'linear-gradient(266deg,#0163E0 -55.06%,#08B2D2 131.43%'

  return (
    <Flex flexDirection="column">
      <Flex mb="12px" alignItems="center">
        <Text fontSize="16px" fontWeight={600}>
          {t('Default Transaction Speed (GWEI)')}
        </Text>
        <QuestionHelper
          text={t(
            'Adjusts the gas price (transaction fee) for your transaction. Higher GWEI = higher speed = higher fees',
          )}
          placement="top-start"
          ml="4px"
        />
      </Flex>
      <Flex flexWrap="wrap">
        <Button
          mt="4px"
          mr="4px"
          scale="sm"
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.rpcDefault)
          }}
          style={{
            background: gasPrice === GAS_PRICE_GWEI.rpcDefault ? activeBg : '#191F39',
            color: gasPrice === GAS_PRICE_GWEI.rpcDefault ? 'white' : '#90A3BF',
            borderRadius: '100px',

          }}
        >
          {t('RPC Default')}
        </Button>
        <Button
          mt="4px"
          mr="4px"
          scale="sm"
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.default)
          }}
          style={{
            background: gasPrice === GAS_PRICE_GWEI.default ? activeBg : '#191F39',
            color: gasPrice === GAS_PRICE_GWEI.default ? 'white' : '#90A3BF',
            borderRadius: '100px',
          }}
        >
          {t('Standard (%gasPrice%)', { gasPrice: GAS_PRICE.default })}
        </Button>
        <Button
          mt="4px"
          mr="4px"
          scale="sm"
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.fast)
          }}
          style={{
            background: gasPrice === GAS_PRICE_GWEI.fast ? activeBg : '#191F39',
            color: gasPrice === GAS_PRICE_GWEI.fast ? 'white' : '#90A3BF',
            borderRadius: '100px',
          }}
        >
          {t('Fast (%gasPrice%)', { gasPrice: GAS_PRICE.fast })}
        </Button>
        <Button
          mr="4px"
          mt="4px"
          scale="sm"
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.instant)
          }}
          style={{
            background: gasPrice === GAS_PRICE_GWEI.instant ? activeBg : '#191F39',
            color: gasPrice === GAS_PRICE_GWEI.instant ? 'white' : '#90A3BF',
            borderRadius: '100px',
          }}
        >
          {t('Instant (%gasPrice%)', { gasPrice: GAS_PRICE.instant })}
        </Button>
      </Flex>
    </Flex>
  )
}

export default GasSettings
