import { FACTORY_ADDRESS, INIT_CODE_HASH } from '../common/swap'
import IceChain from '../ice-chain'

export const peta: IceChain = {
  id: 234,
  name: 'Peta Mainnet',
  features: ['swap', 'info', 'farms', 'bridge', 'staking', 'locks'],
  network: 'peta',
  rpcUrls: {
    public: 'https://mainnet.petachain.io',
    default: 'https://mainnet.petachain.io',
  },
  blockExplorers: {
    default: { name: 'PetaScan', url: 'https://petascan.io' },
  },
  nativeCurrency: {
    name: 'PETA',
    symbol: 'PETA',
    decimals: 18,
  },
  blockInterval: 10,
  multicall: {
    address: '0xbC1dc12aB4955Ba41F32D7Ee605Fde78228368eC',
    blockCreated: 2938,
  },
  locks: {
    factoryAddress: '0xa06fef11971249AbcbAF1F80046C1bEE718f44bD',
  },
  wrappedNative: {
    address: '0xCd1b64b0b75CF3e536C5600659c8D0BE07383058',
    decimals: 18,
    symbol: 'WPETA',
    name: 'Wrapped Peta',
  },
  swap: {
    factoryAddress: FACTORY_ADDRESS,
    initCodeHash: INIT_CODE_HASH,
  },
}
