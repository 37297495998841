import { ChainId, WETH9, ERC20Token } from '@pancakeswap/sdk'

export const maticTokens = {
  wmatic: WETH9[ChainId.MATIC],
  usdt: new ERC20Token(
    ChainId.MATIC,
    '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    18,
    'USDT',
    'USD Tether',
    'https://tether.to',
  ),
  swich: new ERC20Token(
    ChainId.PETA,
    '0x1C079Cff536de408B722A9D2c99ecf91Fc7201EC',
    18,
    'SWICH',
    'Sandwich',
    'https://petaswap.com',
  ),
}
