import { peta } from './peta'
import { bsc } from './bsc'
import { matic } from './polygon'

export const chainMap = {
  peta,
  bsc,
  matic,
}
export const chains = Object.values(chainMap)

export const getChain = (chainId: number) => {
  return chains.find((chain) => chain.id === chainId)
}

export enum ChainId {
  PETA = peta.id,
  BSC = bsc.id,
  MATIC = matic.id,
}
