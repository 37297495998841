import { AtomBox } from "@pancakeswap/ui/components/AtomBox";
import { useIsomorphicLayoutEffect } from "framer-motion";
import debounce from "lodash/debounce";
import React, { useCallback, useRef } from "react";
import { Box } from "../Box";
import { DropdownMenuItemType } from "../DropdownMenu/types";
import MenuItem from "../MenuItem/MenuItem";
import { ChevronLeftIcon, ChevronRightIcon, OpenNewIcon } from "../Svg";
import StyledSubMenuItems, {
  LeftMaskLayer,
  RightMaskLayer,
  StyledSubMenuItemWrapper,
  SubMenuItemWrapper,
} from "./styles";
import { Text } from "../Text";
import { SubMenuItemsProps } from "./types";
import { isMobile } from "react-device-detect";

import "./menu.css";

const SUBMENU_CHEVRON_CLICK_MOVE_PX = 100;
const SUBMENU_SCROLL_DEVIATION = 3;

const SubMenuItems: React.FC<React.PropsWithChildren<SubMenuItemsProps>> = ({
  items = [],
  activeItem,
  isMobileOnly = false,
  withMaskLayout = true,
  ...props
}) => {
  const scrollLayerRef = useRef<HTMLDivElement>(null);
  const chevronLeftRef = useRef<HTMLDivElement>(null);
  const chevronRightRef = useRef<HTMLDivElement>(null);
  const layerController = useCallback(() => {
    if (!scrollLayerRef.current || !chevronLeftRef.current || !chevronRightRef.current) return;
    const scrollLayer = scrollLayerRef.current;
    if (scrollLayer.scrollLeft !== 0) chevronLeftRef.current.classList.add("show");
    else chevronLeftRef.current.classList.remove("show");
    if (scrollLayer.scrollLeft + scrollLayer.offsetWidth < scrollLayer.scrollWidth - SUBMENU_SCROLL_DEVIATION)
      chevronRightRef.current.classList.add("show");
    else chevronRightRef.current.classList.remove("show");
  }, []);

  useIsomorphicLayoutEffect(() => {
    layerController();
  }, [layerController]);

  return (
    <>
      <AtomBox display={{ xs: "none", sm: "block" }} backgroundColor="backgroundBody" width="100%" asChild>
        <SubMenuItemWrapper $isMobileOnly={isMobileOnly} {...props}>
          <AtomBox display={{ xs: "none", md: "none" }} justifyContent="flex-start" asChild>
            <LeftMaskLayer
              ref={chevronLeftRef}
              onClick={() => {
                if (!scrollLayerRef.current) return;
                scrollLayerRef.current.scrollLeft -= SUBMENU_CHEVRON_CLICK_MOVE_PX;
              }}
            >
              <ChevronLeftIcon />
            </LeftMaskLayer>
          </AtomBox>
          <AtomBox display={{ xs: "none", md: "none" }} justifyContent="flex-end" asChild>
            <RightMaskLayer
              ref={chevronRightRef}
              onClick={() => {
                if (!scrollLayerRef.current) return;
                scrollLayerRef.current.scrollLeft += SUBMENU_CHEVRON_CLICK_MOVE_PX;
              }}
            >
              <ChevronRightIcon />
            </RightMaskLayer>
          </AtomBox>
          {items.length > 0 && (
            <StyledSubMenuItems
              justifyContent={[isMobileOnly ? "flex-end" : "center", null, "start"]}
              gap="10px"
              backgroundColor="#111527"
              className="menu"
              borderRadius="20px"
              pl={["12px", null, "8px"]}
              borderColor="#2d2d324d"
              borderTopWidth={["5px", null, null, "10px"]}
              borderRightWidth={["5px", null, null, "10px"]}
              borderLeftWidth={["5px", null, null, "10px"]}
              borderBottomWidth={["5px  !important", null, null, "10px !important"]}
              borderStyle="solid"
              marginTop={56}
              marginBottom={32}
              marginX={[24, 24, 24, "auto"]}
              maxWidth={1200}
              padding="8px"
              onScroll={debounce(layerController, 100)}
              ref={scrollLayerRef}
            >
              {items.map(({ label, href, icon, itemProps, type, disabled }, index, array) => {
                const Icon = icon;
                const isExternalLink = type === DropdownMenuItemType.EXTERNAL_LINK;
                const linkProps = isExternalLink
                  ? {
                      as: "a",
                      target: "_blank",
                    }
                  : {};

                const isActive = href === activeItem;
                const isLastItem = index === array.length - 1;
                const mrValue = isLastItem ? 0 : 20;

                return (
                  label && (
                    <StyledSubMenuItemWrapper
                      key={label}
                      // mr={`${mrValue}px`}
                      background={
                        isActive
                          ? `var(--Background-Gradient, linear-gradient(266deg, #0163E0 -55.06%, #08B2D2 131.43%))`
                          : `var(--asa, #191F39)`
                      }
                      borderRadius="12px"
                      padding="10px"
                      className="insideMenu"
                      style={{
                        overflowX: "hidden",
                      }}
                    >
                      <MenuItem
                        href={href}
                        scrollLayerRef={scrollLayerRef}
                        isActive={isActive}
                        isDisabled={disabled}
                        variant="subMenu"
                        {...itemProps}
                        {...linkProps}
                      >
                        {Icon && <Icon color={isActive ? "secondary" : "textSubtle"} mr="4px" />}
                        <Text
                          margin={0}
                          color={isActive ? "#fff" : "#90A3BF"}
                          textAlign="center"
                          fontSize="16px"
                          fontWeight={600}
                          style={{
                            WebkitTextFillColor: isActive ? "#fff" : "#90A3BF",
                          }}
                        >
                          {label}
                        </Text>
                        {isExternalLink && (
                          <Box display={["none", null, "flex"]} style={{ alignItems: "center" }} ml="4px">
                            <OpenNewIcon color="textSubtle" />
                          </Box>
                        )}
                      </MenuItem>
                    </StyledSubMenuItemWrapper>
                  )
                );
              })}
            </StyledSubMenuItems>
          )}
        </SubMenuItemWrapper>
      </AtomBox>
    </>
  );
};

export default SubMenuItems;
