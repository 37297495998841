import { ChainId, ERC20Token } from '@pancakeswap/sdk'
import { petaTokens } from './234'
import { bscTokens } from './56'
import { maticTokens } from './137'

export const USD: Record<ChainId, ERC20Token> = {
  [ChainId.PETA]: petaTokens.usdt,
  [ChainId.BSC]: bscTokens.usdt, // todo: add proper BSC USDT
  [ChainId.MATIC]: maticTokens.usdt, // todo: add proper BSC USDT
}

export const ICE: Record<ChainId, ERC20Token> = {
  [ChainId.PETA]: petaTokens.swich,
  [ChainId.BSC]: petaTokens.swich, // todo: add proper BSC ICE
  [ChainId.MATIC]: maticTokens.swich, // todo: add proper BSC ICE
}
